import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { PlatoAlertComponent } from "../../components/plato-alert/plato-alert.component";

@Injectable({
  providedIn: "root",
})
export class PlatoAlertService {
  private alertContainer: ViewContainerRef;
  private componentRef: ComponentRef<PlatoAlertComponent>;

  constructor(private resolver: ComponentFactoryResolver) {}

  public destroyAlert() {
    if (this.componentRef) {
      console.debug(`[TICKER SERVICE]: Plato Alert Dismissed: ${this.componentRef.instance.errorMessage}`);
      this.componentRef.destroy();
    }
    if (this.alertContainer) {
      this.alertContainer.clear();
    }
  }

  public showAlert(message: string) {
    if (!this.alertContainer) return;
    this.destroyAlert();
    const factory = this.resolver.resolveComponentFactory(PlatoAlertComponent);
    this.componentRef = this.alertContainer.createComponent(factory);
    this.componentRef.instance.errorMessage = message;
    console.debug(`[TICKER SERVICE]: Plato Alert Initialized: ${this.componentRef.instance.errorMessage}`);
  }

  public initComponent(alertComponent: ViewContainerRef) {
    if (this.alertContainer) return;
    this.alertContainer = alertComponent;
  }
}
